import React from 'react'
import Layout from '../layouts/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Flex } from 'theme-ui'
import { Icon } from '../components/Icon'
import BasicHero from '../components/Hero/BasicHero'
import HeroSvg from '../components/Svg/CreditSolutionHeroSvg'

const CreditSolutions = () => {
  return (
    <Layout theme="light">
      <BasicHero
        bgColor="#ffffff"
        theme="light"
        reverse
        heading="An ever-evolving <br /> consumer credit lender"
        image={<HeroSvg />}
        subHeading=" Our diversified products allow us to meet the changing needs and
            preferences of our customers."
      />
      <section
        sx={{
          bg: '#f1f2f2',
        }}
      >
        <Container
          sx={{
            flexDirection: 'column',
            gap: 5,
          }}
        >
          <h2>Credit Solutions</h2>
          <Flex
            sx={{
              alignItems: 'center',
              flexDirection: ['column', null, null, 'row'],
            }}
          >
            <div
              sx={{
                bg: 'white',
                px: [4, null, null, 5],
                py: [4, null, null, 50],
                mx: [4, null, null, 0],
                border: 'solid 1px #e8e8e8',
                borderRadius: 4,
                mb: [4, null, null, 0],
              }}
            >
              <h3
                sx={{
                  pb: 4,
                  mt: 0,
                }}
              >
                U.S. Lending Businesses
              </h3>
              <Flex>
                <Icon name="check-mark" size={20} alt="checkmark" />
                <p
                  sx={{
                    maxWidth: ['100%', null, null, 430],
                    m: 0,
                    px: 3,
                    textAlign: 'left',
                  }}
                >
                  <b>Direct lending:</b> installment loans and auto loans
                  offered in branches across the U.S. with market leading brands
                  each serving unique segments of the near prime credit spectrum
                </p>
              </Flex>
            </div>
            <Flex
              sx={{
                flexDirection: ['row', null, null, 'column'],
                flexWrap: 'wrap',
                justifyContent: ['center', null, null, 'space-between'],
                alignItems: 'center',
                height: ['100%'],
                width: ['100%', null, null, 500],
              }}
            >
              <Flex
                sx={{
                  gap: 4,
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pb: [4, null, 0],
                }}
              >
                <StaticImage
                  src="../images/brands/Heights.png"
                  alt="heights"
                  placeholder="none"
                />
                <StaticImage
                  src="../images/brands/Southern.png"
                  placeholder="none"
                  alt="southern"
                />
                <StaticImage
                  src="../images/brands/Covington.png"
                  placeholder="none"
                  alt="covington"
                />
                <StaticImage
                  src="../images/brands/QuickCredit.png"
                  placeholder="none"
                  alt="quickcredit"
                  width={200}
                />
                <StaticImage
                  src="../images/brands/1stHeritageCredit.png"
                  placeholder="none"
                  alt="1st heritage"
                  width={200}
                />
              </Flex>
            </Flex>
          </Flex>
          <div
            sx={{
              height: 1,
              width: '100%',
              bg: 'hsla(180,2%,62%,.25)',
            }}
          ></div>
          <Flex
            sx={{
              alignItems: 'center',
              flexDirection: ['column', null, null, 'row'],
              pb: 5,
            }}
          >
            <div
              sx={{
                bg: 'white',
                px: [4, null, null, 5],
                py: [4, null, null, 50],
                mx: [4, null, null, 0],
                border: 'solid 1px #e8e8e8',
                borderRadius: 4,
                mb: [4, null, null, 0],
              }}
            >
              <h3
                sx={{
                  pb: 4,
                  mt: 0,
                }}
              >
                Canadian Lending Businesses
              </h3>
              <Flex>
                <Icon name="check-mark" size={20} />
                <p
                  sx={{
                    maxWidth: ['100%', null, null, 430],
                    m: 0,
                    px: 3,
                  }}
                >
                  <b>Direct lending:</b> flexible lines of credit, auto equity
                  loans and small loans offered in branches and online across a
                  majority of the provinces (products vary by province and
                  channel)
                </p>
              </Flex>
            </div>
            <Flex
              sx={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: ['100%', null, null, 500],
              }}
            >
              <Flex
                sx={{
                  gap: 3,
                }}
              >
                <StaticImage
                  src="../images/brands/CashMoney.png"
                  placeholder="none"
                  alt="Cash Money"
                />
                <StaticImage
                  src="../images/brands/LendDirect.png"
                  placeholder="none"
                  alt="Lend Direct"
                />
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </section>
    </Layout>
  )
}

export default CreditSolutions
